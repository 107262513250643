import { useAuthorization } from '~/composables/auth/useAuthorization';
import { useAuthStore } from '~/stores/auth/useAuthStore';

/** Middleware проверяет авторизацию. */
export default defineNuxtRouteMiddleware(async () => {
    const store = useAuthStore();
    
    if(!store.isAuthorized) {
        const { redirectToLogoutUrl } = useAuthorization();
        redirectToLogoutUrl();
    }
})